import { IdMap, RArray } from "../collections";
import { toPairs } from "../core";
import { makeImmutable } from "../core/makeImmutable";
import { PMenuSectionItemParser } from "../parsing";
import { PMenu } from "../presentation/PMenu";
import { PRestaurant } from "../presentation/PRestaurant";
import { PCustomParameterTypeParser, PMenuSectionParser, PModifierTypeParser, PProductTypeParser, VariantCreatorFactory, } from "./presentation";
import { Sentry } from "..";
export class PRestaurantParser {
    constructor(params) {
        this.localizationProvider = params.localizationProvider;
        this.isApplePaySupported = params.isApplePaySupported;
        this.translatedParser = params.translatedParser;
        makeImmutable(this);
    }
    parse(params) {
        const domainMenu = params.domainRestaurant.menu;
        const customParameterTypeParser = new PCustomParameterTypeParser({
            domainCustomParameterTypes: domainMenu.customParameterTypes,
            translatedParser: this.translatedParser,
        });
        const customParameterTypes = this.parseCustomParameterTypes(params.input.catalogue, customParameterTypeParser);
        const modifierTypeParser = new PModifierTypeParser({
            domainModifiers: domainMenu.modifierTypes,
            translatedParser: this.translatedParser,
        });
        const menuArragment = domainMenu.arrangments.sample;
        if (menuArragment === null) {
            Sentry.logError("No menu sections defined");
        }
        const domainMenuSections = menuArragment !== null
            ? menuArragment.sections
            : IdMap.empty();
        const modifierTypes = this.parseModifierTypes(params.input.catalogue, modifierTypeParser);
        const productTypeParser = new PProductTypeParser({
            domainProductTypes: domainMenu.productTypes,
            domainSchedules: domainMenu.schedules,
            translatedParser: this.translatedParser,
            modifierTypes,
        });
        const productTypes = this.parseProductTypes(params.input.catalogue, productTypeParser);
        const variantCreatorFactory = new VariantCreatorFactory({
            productCategories: domainMenu.productCategories,
            productTypes: domainMenu.productTypes,
            purchaseOrderScope: params.purchaseOrderScope,
        });
        const pMenuSectionItemParser = new PMenuSectionItemParser({
            productTypes,
            customParameterTypes,
            localizationProvider: this.localizationProvider,
            translatedParser: this.translatedParser,
            variantCreatorFactory,
            purchaseOrderPayload: params.purchaseOrderPayload,
        });
        const menuSectionParser = new PMenuSectionParser({
            domainMenuSections,
            domainSchedules: domainMenu.schedules,
            translatedParser: this.translatedParser,
            pMenuSectionItemParser,
        });
        return new PRestaurant({
            domain: params.domainRestaurant,
            name: params.input.name,
            slug: params.input.slug,
            address: params.input.address,
            menu: new PMenu({
                domain: domainMenu,
                sections: this.parseSections(params.input.catalogue, menuSectionParser),
            }),
            productTypes,
            modifierTypes,
            customParameterTypes,
            promotionTypes: params.promotionTypes,
            isApplePaySupported: this.isApplePaySupported,
            franchiseName: params.franchiseName,
            phoneNumbers: params.input.order_phone_numbers,
        });
    }
    parseProductTypes(catalogue, parser) {
        return IdMap.fromIterable(toPairs(catalogue.products).mapOptional(([productId, product]) => {
            const productCategory = catalogue.categories[product.category];
            return parser.parse(productId, productCategory.name, product);
        }));
    }
    parseModifierTypes(catalogue, parser) {
        return IdMap.fromIterable(toPairs(catalogue.modifiers).mapOptional(([modifierId, modifier]) => parser.parse(modifierId, modifier)));
    }
    parseCustomParameterTypes(catalogue, parser) {
        return IdMap.fromIterable(toPairs(catalogue.customParameters).mapOptional(([customParameterId, customParameter]) => parser.parse(customParameterId, customParameter)));
    }
    parseSections(catalogue, parser) {
        const sections = new RArray(toPairs(catalogue.menus).raw.flatMap(([_, menu]) => menu.sections)).mapOptional((sectionId) => {
            const section = catalogue.sections[sectionId];
            return parser.parse(sectionId, section);
        });
        return IdMap.fromIterable(sections);
    }
}
