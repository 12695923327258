import { RSet, } from "..";
export class LabelAvailabilityVisitor {
    constructor() {
        this.reasons = RSet.empty();
    }
    visitAvailable() {
        // NOTICE Intentionally empty
    }
    visitUnavailable(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitMaybe() {
        // NOTICE Intentionally empty
    }
    visitAsync(availability) {
        availability.asyncAvailability.accept(this);
    }
    visitBoolean(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitComposite(availability) {
        availability.availabilities.forEach((childAvailability) => {
            return childAvailability.accept(this);
        });
    }
    visitDependent(availability) {
        availability.dependency.accept(this);
    }
    visitParametrized(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
            this.reasons = this.reasons.union(availability.unavailabilityCausingParameters.internal.fulfillmentMethod);
        }
    }
    visitSchedule(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
    visitPreorder(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.reasons = this.reasons.union(availability.unavailabilityReasons);
        }
    }
}
