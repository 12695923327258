import { Availability, RDate } from "../..";
export const OrderingAvailability = {
    create(params) {
        switch (params.backendOrderingAvailability.tag) {
            case "OrderingImpossible":
                return {
                    availability: Availability.unavailable("OrderingAvailability"),
                    nextAvailableDate: null,
                };
            case "OrderingPossible": {
                const { nextAvailableThatDay } = params.backendOrderingAvailability;
                return {
                    availability: Availability.available(),
                    nextAvailableDate: nextAvailableThatDay === null
                        ? null
                        : RDate.todayAtHour(nextAvailableThatDay, params.timezone),
                };
            }
        }
    },
};
