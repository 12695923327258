import { translate, t } from "../../I18n";
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { ErrorBox, SelectableFieldController } from "../../form";
export class FulfillmentTimeController {
    constructor(params) {
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.fulfillmentTime = params.fulfillmentTime;
        this.fulfillmentHour = new SelectableFieldController({
            initialValue: this.fulfillmentTime.hourValue,
            onUpdate: (value) => (this.fulfillmentTime.hourValue = value),
            onClear: () => (this.fulfillmentTime.hourValue = null),
            errorBox: new ErrorBox({
                domainObjects: [this.fulfillmentTime],
                errorMessages: {
                    HourValueMissing: translate(t._common.form.errors.required),
                    HourOptionsMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        this.fulfillmentDate = new SelectableFieldController({
            initialValue: this.fulfillmentTime.dateValue,
            onUpdate: (value) => (this.fulfillmentTime.dateValue = value),
            onClear: () => (this.fulfillmentTime.dateValue = null),
            errorBox: new ErrorBox({
                domainObjects: [this.fulfillmentTime],
                errorMessages: {
                    DateValueMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        this.fulfillmentOption = new SelectableFieldController({
            initialValue: this.fulfillmentTime.optionValue,
            onUpdate: (value) => (this.fulfillmentTime.optionValue = value),
            onClear: () => (this.fulfillmentTime.optionValue = null),
            options: this.fulfillmentTime.options.map((option) => option.type),
            errorBox: new ErrorBox({
                domainObjects: [this.fulfillmentTime],
                errorMessages: {
                    OptionValueMissing: translate(t._common.form.errors.required),
                },
            }),
        });
        makeAutoObservable(this);
    }
    get fields() {
        return {
            fulfillmentOption: this.fulfillmentOption,
            fulfillmentDate: this.fulfillmentDate,
            fulfillmentHour: this.fulfillmentHour,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        return this.fieldsArray.some((field) => field.isInvalid);
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.fulfillmentInstructions;
    }
    clear() {
        this.fieldsArray.forEach((field) => field.clear());
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
    // Set controller values to the domain value
    restore() {
        const { domain } = this.fulfillmentTime;
        if (domain === null) {
            this.fulfillmentOption.clear();
            return;
        }
        switch (domain.type) {
            case "ASAP":
                this.fulfillmentTime.options.forEach((option) => {
                    if (option.type === "ASAP" && option.isAvailable) {
                        this.fulfillmentOption.change("ASAP");
                    }
                });
                break;
            case "OnTime":
                this.fulfillmentTime.options.forEach((option) => {
                    if (option.type === "InFuture" && option.isAvailable) {
                        this.fulfillmentOption.change("InFuture");
                        this.fulfillmentDate.change(domain.date.fullDate);
                        this.fulfillmentHour.change(domain.date.fullHour);
                    }
                    else if (option.type === "Today" && option.isAvailable) {
                        this.fulfillmentOption.change("Today");
                        this.fulfillmentHour.change(domain.date.fullHour);
                    }
                });
                break;
        }
    }
}
